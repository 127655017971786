import React, { type ReactNode, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import {
  Button,
  ButtonProps,
  Divider,
  Grid,
  TextField,
  TextFieldProps,
  alpha,
  styled,
} from "@mui/material";

import { useGetFunnelsQuery } from "api/api";
import { fonts } from "assets/typography";
import {
  setActiveStep,
  setFunnelMethod,
  setFunnelProfile,
  setFunnelType,
  setRegistrationNumber,
} from "store/funnel/funnel.reducer";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { setSnackbarOpen } from "store/snackbar";
import { theme } from "themes";
import {
  type ActionProps,
  ERROR_MESSAGE,
  ERROR_SEVERITY,
  FUNNEL_COMPONENT_TYPE,
  FUNNEL_METHOD,
  FUNNEL_TYPE,
  PROFILE,
} from "types";
import {
  REGEX_PLATE,
  actions,
  getAllowedProfiles,
  getLocaleFromUrl,
} from "utils";

type CardCustomProps = {
  children?: ReactNode;
  enumActions: FUNNEL_METHOD[] | FUNNEL_TYPE[];
};

const GridCentered = styled(Grid)`
  display: grid;
  align-content: center;
  justify-items: center;
`;

export const CardText = styled("h5")`
  font-size: ${theme.spacing(1.75)};
  font-family: ${fonts.nouvelRLight};
  margin: ${theme.spacing(5, 1, 3, 1)};
`;

export const Subtitle = styled("h5")`
  display: flex;
  font-size: ${theme.spacing(2)};
  margin: ${theme.spacing(3, 3, 4, 3)};
`;

const CardCustom: React.FunctionComponent<CardCustomProps> = ({
  children,
  enumActions,
}) => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const navigate = useNavigate();

  const { data: funnels } = useGetFunnelsQuery(getLocaleFromUrl());
  const { funnelType } = useAppSelector((state) => state.funnel);

  const [inputPlate, setInputPlate] = useState<string>("");

  const handleAction = (action: ActionProps) => {
    switch (action.actionType) {
      case FUNNEL_TYPE.SHORT:
      case FUNNEL_TYPE.LONG: {
        dispatch(setFunnelType(action.actionType));
        let allowedProfiles =
          funnels &&
          action.actionType &&
          getAllowedProfiles(funnels, action.actionType);
        let funnelProfile;
        if (allowedProfiles?.includes(PROFILE.PRIVATE)) {
          funnelProfile = PROFILE.PRIVATE;
        } else if (allowedProfiles && allowedProfiles.length > 0) {
          funnelProfile = allowedProfiles[0];
        }
        if (!funnelProfile) {
          dispatch(
            setSnackbarOpen({
              open: true,
              message: ERROR_MESSAGE.NOT_FOUND,
              variant: ERROR_SEVERITY.ERROR,
            }),
          );
          return;
        } else {
          dispatch(setFunnelProfile(funnelProfile));
        }
        break;
      }
      case FUNNEL_METHOD.WITH_REGISTRATION_PLATE:
      case FUNNEL_METHOD.WITHOUT_REGISTRATION_PLATE: {
        dispatch(setFunnelMethod(action.actionType));
        dispatch(setActiveStep(0));
        break;
      }
    }
    navigate(intl.formatMessage({ id: action.redirectPath }));
  };

  return (
    <GridCentered pt={0} container>
      {funnels &&
        funnelType &&
        getAllowedProfiles(funnels, funnelType).length > 1 && <>{children}</>}
      {actions
        .filter(({ actionType }) =>
          Object.values(enumActions).includes(actionType),
        )
        .map((action, index) => (
          <GridCentered width={"100%"} key={action.actionType}>
            {action.subtitle && (
              <Grid item lg={12}>
                <Subtitle sx={{ textAlign: "center" }}>
                  <FormattedMessage id={action.subtitle} />
                </Subtitle>
              </Grid>
            )}
            {action.componentType === FUNNEL_COMPONENT_TYPE.INPUT && (
              <Grid width="100%" md={9} xs={12}>
                <TextField
                  sx={{ width: "100%" }}
                  {...(action.componentProps as TextFieldProps)}
                  {...(action.actionType ===
                    FUNNEL_METHOD.WITH_REGISTRATION_PLATE && {
                    InputProps: {
                      disableUnderline: true,
                      endAdornment: (
                        <Button
                          variant="contained"
                          onClick={() => {
                            dispatch(setRegistrationNumber(inputPlate));
                            handleAction(action);
                          }}
                          sx={{
                            width: "auto",
                            marginBottom: 0,
                            border: 0,
                          }}
                          disabled={!REGEX_PLATE.test(inputPlate)}
                        >
                          <FormattedMessage id={action.label} />
                        </Button>
                      ),
                    },
                  })}
                  value={inputPlate}
                  variant="standard"
                  placeholder={intl.formatMessage({ id: action.placeholder })}
                  onChange={(e) => {
                    setInputPlate(e.target.value);
                  }}
                />
              </Grid>
            )}
            {action.componentType === FUNNEL_COMPONENT_TYPE.BUTTON && (
              <Grid width="100%" md={9} xs={12}>
                <Button
                  {...(action.componentProps as ButtonProps)}
                  onClick={() => handleAction(action)}
                  sx={{ width: "100%" }}
                >
                  <FormattedMessage id={action.label} />
                </Button>
              </Grid>
            )}
            {index !== enumActions.length - 1 && (
              <Divider
                sx={{
                  p: theme.spacing(2),
                  width: "100%",
                  color: alpha(theme.palette.primary.contrastText, 0.6),
                }}
              >
                <FormattedMessage id={"funnel.divider"} />
              </Divider>
            )}
          </GridCentered>
        ))}
      <CardText>
        <FormattedMessage id={"funnel.card.text"} />
      </CardText>
    </GridCentered>
  );
};

export default CardCustom;
