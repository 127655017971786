import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Switch } from "@mui/material";

import { useGetFunnelsQuery } from "api/api";
import { FunnelLayout } from "layouts";
import { partialReset, setFunnelProfile } from "store/funnel/funnel.reducer";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { setSnackbarOpen } from "store/snackbar";
import {
  ERROR_MESSAGE,
  ERROR_SEVERITY,
  type FUNNEL_METHOD,
  PROFILE,
} from "types";
import { getAllowedMethods, getLocaleFromUrl } from "utils";

const FunnelMethod: React.FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const { data: funnels } = useGetFunnelsQuery(getLocaleFromUrl());
  const { funnelType, funnelProfile } = useAppSelector((state) => state.funnel);
  const [funnelMethods, setFunnelMethods] = useState<FUNNEL_METHOD[]>([]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      setFunnelProfile(e.target.checked ? PROFILE.PRO : PROFILE.PRIVATE),
    );
  };

  useEffect(() => {
    dispatch(partialReset());
    if (funnels && funnelType && funnelProfile) {
      let allowedMethods = getAllowedMethods(
        funnels,
        funnelType,
        funnelProfile,
      );
      setFunnelMethods(allowedMethods);
      if (allowedMethods.length > 0) {
        return;
      }
    }
    dispatch(
      setSnackbarOpen({
        open: true,
        message: ERROR_MESSAGE.NOT_FOUND,
        variant: ERROR_SEVERITY.ERROR,
      }),
    );
  }, [funnelProfile]);

  return (
    <FunnelLayout
      title={intl.formatMessage({ id: "funnel.method.title" }).toUpperCase()}
      enumActions={funnelMethods}
      switchProps={{
        control: (
          <Switch
            onChange={handleChange}
            checked={funnelProfile === PROFILE.PRO}
          />
        ),
        label: <FormattedMessage id={"funnel.profile.switch.label"} />,
      }}
    />
  );
};

export default FunnelMethod;
