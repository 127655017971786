import { Configuration } from "types/common.types"
import { CONFIG, CONFIGURATION_FILE, WINDOW_PROPERTIES } from "types/enums.types"

declare global {
  interface Window {
    CONFIGURATION: Configuration
  }
}

window[WINDOW_PROPERTIES.CONFIGURATION] = {
  API_BASE_HOSTNAME: "",
  ENV: "",
}

const getConfiguration = (key: keyof Configuration) => window?.[WINDOW_PROPERTIES.CONFIGURATION]?.[key]

const loadConfiguration = () =>
  fetch(CONFIGURATION_FILE)
    .then((r) => r.json())
    .then((data) => {
      Object.values(CONFIG).forEach((k: CONFIG) => (window[WINDOW_PROPERTIES.CONFIGURATION][k] = data[k]))
    })

export { getConfiguration, loadConfiguration }
