import {
  useLazyGetFunnelConfigurationsQuery,
  useLazyGetNextQuestionQuery,
  useLazyGetQuotationQuery,
} from "api/api";
import { useAppSelector } from "store/hooks";
import { CONTEXT, type QuestionDTO, type SectionDTO } from "types";
import { COMPLETION_STATE, getLocaleFromUrl } from "utils";

const useCallBackend = (question?: QuestionDTO, interSection?: boolean) => {
  const {
    registrationNumber,
    funnelType,
    funnelProfile,
    funnelMethod,
    state,
    formInit,
    activeStep,
  } = useAppSelector((state) => state.funnel);

  const slicer = interSection ? 0 : 1;
  const nextQuestionIds = formInit?.sections.reduce(
    (accumulator: string[], section: SectionDTO) => {
      const questions = section.questions;
      const answeredIndex = questions.findIndex((q) => q.id === question?.id);
      if (answeredIndex !== -1) {
        for (const question of questions.slice(answeredIndex + slicer)) {
          accumulator.push(question.id);
          if (question.primary) {
            break;
          }
        }
      }
      return accumulator;
    },
    [],
  );

  const [fetchQuestions, { isLoading: isFetchingQuestions }] =
    useLazyGetFunnelConfigurationsQuery();

  const commonBody = {
    locale: getLocaleFromUrl(),
    profile: funnelProfile,
    funnelType: funnelType,
    funnelMethod: funnelMethod,
    context: CONTEXT.RENAULT,
    registrationNumber,
  };

  const getQuestion = () => {
    if (funnelType && funnelProfile && funnelMethod) {
      fetchQuestions(commonBody);
    }
  };

  const [fetchNextQuestion, { isLoading: isFetchingNextStep }] =
    useLazyGetNextQuestionQuery();

  const answers = state?.find(
    (questionState) => questionState.id === question?.id,
  )?.answers;

  const firstQuestionOfNextSection =
    formInit?.sections[
      formInit.sections.indexOf(formInit.sections[activeStep]) + 1
    ]?.questions[0];

  const getNextStep = () => {
    if (
      !!(
        funnelProfile &&
        funnelMethod &&
        funnelType &&
        state &&
        nextQuestionIds?.length &&
        ((answers && answers.length) || question === firstQuestionOfNextSection)
      )
    ) {
      fetchNextQuestion({
        ...commonBody,
        questionIds: nextQuestionIds,
        state,
      });
    }
  };

  const [
    fetchQuotation,
    { isLoading: isFetchingQuotation, data: quotationData },
  ] = useLazyGetQuotationQuery();

  const getQuotation = () => {
    if (funnelType && funnelProfile && funnelMethod && state) {
      fetchQuotation({
        ...commonBody,
        state: [...state, ...COMPLETION_STATE],
      });
    }
  };

  const isLoading =
    isFetchingQuestions || isFetchingNextStep || isFetchingQuotation;

  return {
    getQuestion,
    getNextStep,
    getQuotation,
    isLoading,
    quotationData,
  };
};

export default useCallBackend;
