import { loadConfiguration } from 'env/env';
import React, { type FC, useEffect } from "react";

import { AppRouter } from "./routers";
import useSnackbar from "hooks/useSnackbar";

const App: FC = () => {
  useEffect(() => {
    ;(async () => {
      try {
        await loadConfiguration()
      } catch (e) {
        console.error("Failed to load conf file")
      }
    })()
  }, [])

  useSnackbar();
  return <AppRouter />;
};

export default App;
