import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getConfiguration } from 'env/env';
import { CONFIG } from 'types/enums.types';

import endpoints from "./endpoints";
import type {
  FunnelConfigurationDTO,
  FunnelDTO,
  QuestionNextStepDTO,
  QuestionRequest,
  QuotationDTO,
  QuotationParameters,
  StepRequest,
} from "types";

const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: getConfiguration(CONFIG.API_BASE_HOSTNAME),
    prepareHeaders(headers) {
      headers.set("x-agent", "WEB");
    },
    timeout: 60000,
    credentials: "include",
    redirect: "manual",
  }),
  endpoints: (builder) => ({
    getFunnels: builder.query<FunnelDTO[], string>({
      query: (locale) => ({
        url: endpoints.funnels.get,
        method: "GET",
        params: { locale },
      }),
    }),
    getFunnelConfigurations: builder.query<
      FunnelConfigurationDTO,
      QuestionRequest
    >({
      query: (body) => {
        return {
          url: endpoints.funnelConfigurations.get,
          method: "POST",
          body,
        };
      },
    }),
    getNextQuestion: builder.query<QuestionNextStepDTO[], StepRequest>({
      query: (body) => {
        return {
          url: endpoints.nextQuestion.get,
          method: "POST",
          body,
        };
      },
    }),
    getQuotation: builder.query<QuotationDTO, QuotationParameters>({
      query: (body) => {
        return {
          url: endpoints.quotation.get,
          method: "POST",
          body,
        };
      },
    }),
  }),
});

export const {
  useGetFunnelsQuery,
  useLazyGetFunnelConfigurationsQuery,
  useLazyGetNextQuestionQuery,
  useLazyGetQuotationQuery,
} = api;

export default api;
