import { createSlice } from "@reduxjs/toolkit";

import api from "api";
import type {
  FUNNEL_METHOD,
  FUNNEL_TYPE,
  FunnelConfigurationDTO,
  PROFILE,
  QuestionDTO,
  QuestionState,
  QuotationDTO,
  QuotationParameters,
} from "types";

type FunnelState = {
  registrationNumber?: QuotationParameters["registrationNumber"];
  funnelType?: QuotationParameters["funnelType"];
  funnelProfile?: QuotationParameters["profile"];
  funnelMethod?: QuotationParameters["funnelMethod"];
  state?: QuotationParameters["state"];
  formInit?: FunnelConfigurationDTO;
  quotationResult?: QuotationDTO;
  activeStep: number;
};

const initialState: FunnelState = { activeStep: 0 };

export const funnelReducer = createSlice({
  name: "funnel",
  initialState,
  reducers: {
    partialReset: (state) => {
      state.state = undefined;
      state.formInit = undefined;
    },
    setRegistrationNumber: (state, { payload }: { payload: string }) => {
      state.registrationNumber = payload;
    },
    setFunnelType: (state, { payload }: { payload: FUNNEL_TYPE }) => {
      state.funnelType = payload;
    },
    setFunnelProfile: (state, { payload }: { payload: PROFILE }) => {
      state.funnelProfile = payload;
    },
    setFunnelMethod: (state, { payload }: { payload: FUNNEL_METHOD }) => {
      state.funnelMethod = payload;
    },
    setActiveStep: (state, { payload }: { payload: number }) => {
      state.activeStep = payload;
    },
    setGenericState: (state, { payload }: { payload: QuestionState }) => {
      if (Array.isArray(state.state)) {
        state.state = [
          ...state.state.filter((question) => question.id !== payload.id),
          payload,
        ];
      } else {
        state.state = [payload];
      }
    },
    removeFromState: (
      state,
      {
        payload,
      }: {
        payload: string[] | undefined;
      },
    ) => {
      if (Array.isArray(state.state)) {
        state.state = [
          ...state.state.filter((question) => !payload?.includes(question.id)),
        ];
        const stateIdArray: string[] = [];
        if (state.state) {
          for (let answer of state.state) {
            stateIdArray.push(answer.id);
          }
        }
        if (state.formInit) {
          for (let section of state.formInit.sections) {
            for (let question of section.questions) {
              if (!stateIdArray.includes(question.id)) {
                question.options = [];
              }
            }
          }
        }
      }
    },
    emptyQuotationResult: (state) => {
      state.quotationResult = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      api.endpoints.getFunnelConfigurations.matchFulfilled,
      (state, { payload }) => {
        state.formInit = payload;
      },
    );
    builder.addMatcher(
      api.endpoints.getNextQuestion.matchFulfilled,
      (state, { payload }) => {
        const payloadIdArray: string[] = [];
        for (let question of payload) {
          payloadIdArray.push(question.id);
        }
        if (state.formInit) {
          for (let section of state.formInit.sections) {
            for (let question of section.questions) {
              if (payloadIdArray.includes(question.id)) {
                const foundPayload = payload.find(
                  (el) => el.id === question.id,
                );
                if (foundPayload) {
                  question.options = foundPayload.options;
                  question.subQuestions = foundPayload.subQuestions;
                }
              }
            }
          }
        }
      },
    );
    builder.addMatcher(
      api.endpoints.getQuotation.matchFulfilled,
      (state, { payload }) => {
        state.quotationResult = payload;
      },
    );
  },
});

export default funnelReducer.reducer;

export const {
  partialReset,
  setRegistrationNumber,
  setGenericState,
  removeFromState,
  setFunnelType,
  setFunnelProfile,
  setFunnelMethod,
  setActiveStep,
  emptyQuotationResult,
} = funnelReducer.actions;
