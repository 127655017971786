import { fr, en } from "lang";

enum SUPPORTED_LANGUAGES {
  FR = "fr",
  EN = "en",
}

export const LOCALE_STORAGE_COUNTRY = "country";
export const DEFAULT_LANGUAGE = SUPPORTED_LANGUAGES.FR;

const locale = (): SUPPORTED_LANGUAGES => {
  const browserLocale =
    navigator.languages === undefined
      ? navigator.language
      : navigator.languages[0];

  const tempLanguageCode = (browserLocale || DEFAULT_LANGUAGE)
    .trim()
    .split(/-|_/)[0]
    .toLowerCase();

  const languageCode = (
    Object.values(SUPPORTED_LANGUAGES) as Array<string>
  ).includes(tempLanguageCode)
    ? (tempLanguageCode as SUPPORTED_LANGUAGES)
    : DEFAULT_LANGUAGE;

  localStorage.setItem(LOCALE_STORAGE_COUNTRY, languageCode.toUpperCase());

  return languageCode;
};

const config: Record<SUPPORTED_LANGUAGES, { intl: Record<string, string> }> = {
  [SUPPORTED_LANGUAGES.FR]: {
    intl: fr,
  },
  [SUPPORTED_LANGUAGES.EN]: {
    intl: en,
  },
};

export default config[locale()];
